import removeItem from './remove_item';

addEventListener('turbo:load', (_event) => {
  if (!document.getElementById('edit-bid')) return;
  const removeLineButtons = document.querySelectorAll('.remove-line');
  const removeAdditionButtons = document.querySelectorAll('.remove-addition');

  for (const button of removeLineButtons) {
    button.addEventListener('click', removeItem);
  }
  for (const button of removeAdditionButtons) {
    button.addEventListener('click', removeItem);
  }
});